import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DViewIconText = _resolveComponent("DViewIconText")!

  return (_openBlock(), _createBlock(_component_DViewIconText, {
    icon: _ctx.iconData.icon,
    color: _ctx.iconData.color,
    text: _ctx.iconData.text
  }, null, 8, ["icon", "color", "text"]))
}