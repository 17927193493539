
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        hocRating: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        return {
            color: getHocRatingColor(props.hocRating)
        }
    }
})

function getHocRatingColor(hocRating: number) {
    let color = ''

    if (hocRating === 0) {
        color = 'hoc-lvl-0'
    } else if (hocRating === 1 || hocRating === 2) {
        color = 'hoc-lvl-1-2'
    } else if (hocRating === 3 || hocRating === 4) {
        color = 'hoc-lvl-3-4'
    } else if (hocRating === 5 || hocRating === 6) {
        color = 'hoc-lvl-5-6'
    }

    return color
}
