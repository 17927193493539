
import { defineComponent } from 'vue'
import { alertCircle, warning, helpCircle } from 'ionicons/icons'
import { DeviationClassification } from '@dview/shared/models'
import DViewIconText from '@dview/shared/components/DViewIconText.vue'

export default defineComponent({
    components: {
        DViewIconText,
    },
    props: {
        classificationId: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const iconData = getClassificationIconData(props.classificationId)

        return {
            iconData
        }
    }
})

function getClassificationIconData(classificationId: number) {
    const iconData = {
        icon: '',
        text: '',
        color: ''
    }

    if (classificationId === DeviationClassification.Unclassifed) {
        iconData.icon = helpCircle
        iconData.text = 'N/A'
        iconData.color = '#B3B3B3'
    } else if (classificationId === DeviationClassification.Major) {
        iconData.icon = alertCircle
        iconData.text = 'Major'
        iconData.color = '#B84432'
    } else if (classificationId === DeviationClassification.Minor) {
        iconData.icon = warning
        iconData.text = 'Minor'
        iconData.color = '#F2CD66'
    }

    return iconData
}
