import { customRef, Ref, ref } from 'vue'

/**
 * Helper ref creations for when having to interact with Ionic web components.
 * Also serves the purpose of being able to mock these in unit tests.
 */

export function ionicSliderRef(): IonSliderRef {
    const ionicComponentRef = (ref() as any) as IonSliderRef

    ionicComponentRef.isBeginning = () => {
        return ionicComponentRef.value.$el.isBeginning()
    }

    ionicComponentRef.slideNext = () => {
        return ionicComponentRef.value.$el.slideNext()
    }

    return ionicComponentRef
}

interface IonSliderRef {
    value: any
    isBeginning(): Promise<boolean>
    slideNext(): Promise<void>
}

export function ionicInfiniteScrollRef(): IonInfiniteScrollRef {
    const ionicComponentRef = (ref() as any) as IonInfiniteScrollRef

    ionicComponentRef.complete = () => {
        ionicComponentRef.value.$el.complete()
    }

    return ionicComponentRef
}

interface IonInfiniteScrollRef {
    value: any
    complete(): void
}