
import { computed, defineComponent, getCurrentInstance, ref, watch } from 'vue'
import { useDeviations, useOrganizationAreas, useDeviationsFilter, useDeviationsFilterConfig } from '@dview/logic'
import { OrganizationUnit, DeviationType } from '@dview/shared/models'
import { waitForElement } from '@dview/shared/util/async'
import DViewMessageBanner from '@dview/shared/components/DViewMessageBanner.vue'
import DeviationsNoFilterSelected from '../DeviationsNoAreaSelected.vue'
import DeviationsErrorMessage from '../DeviationsErrorMessage.vue'
import { useInfiniteScroll } from './use-infinite-scroll'
import { useDeviationOverlay, DeviationOverlayAdvanced } from './use-deviation-overlay'
import DeviationGroupComponent from './DeviationGroup.vue'
import DeviationsEmptyMessage from './DeviationsEmptyMessage.vue'
import DeviationOverlay from './DeviationOverlay.vue'
import { useDeviationGrouping } from './use-deviations-grouping'

export default defineComponent({
    components: {
        DeviationGroupComponent,
        DeviationsEmptyMessage,
        DeviationsErrorMessage,
        DeviationsNoFilterSelected,
        DViewMessageBanner,
        DeviationOverlay
    },
    props: {
        deviationType: {
            type: String as () => DeviationType,
            required: true,
            validator: (value: DeviationType) => ['open', 'hoc-rated', 'closed'].includes(value),
        },
    },
    setup(props) {
        const { activeAreasFilter, activeAreaSelected } = useOrganizationAreas()
        const { error, loading, loaded, empty, deviations, loadDeviations } = useDeviations(props.deviationType)
        const { deviations: filteredDeviations } = useDeviationsFilter(deviations, props.deviationType)
        const { infiniteScrollRef, infiniteParentScrollContainerRef, reset: resetInfiniteScroll, renderMoreItems, renderedItems, disabled } = useInfiniteScroll(filteredDeviations)
        const { groupedDeviations } = useDeviationGrouping(filteredDeviations, renderedItems, props.deviationType)

        const { init: initOverlay, hide: hideOverlay } = useDeviationOverlay() as DeviationOverlayAdvanced
        initOverlay() // DeviationList component will be the initiator of the overlay component, for use by its children

        const { active: filterActive, appliedBy: filterAppliedType, reset: clearFilter, filterKey } = useDeviationsFilterConfig(props.deviationType)
        const showWidgetShortcutFilter = ref(false)
        const widgetShortcutFilterApplied = computed(() => filterActive.value && filterAppliedType.value === 'app-logic')
        const widgetShortcutFilterText = computed(() => determineFilterShortcutText())

        const componentRootElement = ref<HTMLElement>()

        // a little hacky, delay needed in order for both transitions to fire properly...
        watch(widgetShortcutFilterApplied, (applied: boolean) => {
            setTimeout(() => {
                showWidgetShortcutFilter.value = applied
            }, 1)
        }, { immediate: true })

        /**
         * When organization filter changes, we call backend to load deviations again.
         * In the same token, the current infinitescroll state and data must be reset,
         * awaiting the new deviation data to return from the server.
         */
        watch([activeAreasFilter, activeAreaSelected], values => {
            const filter = values[0] as OrganizationUnit[]
            const filterSelected = values[1]
            if (filterSelected) {
                loadDeviations(filter)
            }
        }, { immediate: true })

        /**
         * This gets triggered whenever new deviations are loaded or when the user changes his
         * filtering selection.
         */
        watch([filteredDeviations, loaded], async values => {
            const loaded = (values[1] as any) as boolean

           // Remove overlay in case user was showing details prior to switching filters or refresh data,
            // otherwise the overlay would still be visible on return.
            hideOverlay()

            // Reset any prior deviation data piped through the infinite scroll.
            resetInfiniteScroll()

           if (loaded) {
                // Awaiting infiniteScroll element to have been bound to ref during a render tick,
                // then render the first batch of items in the DOM.
                if (infiniteScrollRef.value == null) {
                    await waitForElement(() => infiniteScrollRef.value)
                }
                renderMoreItems() 
            }
        })

        function determineFilterShortcutText() {
            if (!widgetShortcutFilterApplied.value || !filterKey.value) {
                return ''
            } else {
                if (filterKey.value === 'minor') {
                    return 'Only showing Minor deviations'
                } else if (filterKey.value === 'major') {
                    return 'Only showing Major deviations'
                } else if (filterKey.value === 'hoc-low-rated') {
                    return 'Only showing Low Rated deviations'
                } else {
                    return ''
                }
            }
        }

        watch(componentRootElement, element => {
            if (element) {
                infiniteParentScrollContainerRef.value = getParentScrollContainer(element)
            }
        })

        function getParentScrollContainer(element: HTMLElement) {
            let parentScrollContainer = element.parentElement
            while (parentScrollContainer != null && parentScrollContainer.nodeName !== 'ION-CONTENT') {
                parentScrollContainer = parentScrollContainer?.parentElement
            }
            return parentScrollContainer
        }

        return {
            noAreaSelected: computed(() => !activeAreaSelected.value),
            showWidgetShortcutFilter,
            widgetShortcutFilterApplied,
            widgetShortcutFilterText,
            groupedDeviations,
            loading,
            error, 
            empty,
            componentRootElement,
            infiniteScrollRef,
            ionScrollDisabled: disabled,
            renderMoreItems,
            clearFilter
        }
    },
})
