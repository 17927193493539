
import { defineComponent } from 'vue'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { DeviationType } from '@dview/shared/models'

export default defineComponent({
    props: {
        deviationType: {
            type: String as () => DeviationType,
            required: true,
            validator: (value: DeviationType) => ['open', 'hoc-rated', 'closed'].includes(value),
        },
    },
    setup() {
        return {
            checkmarkCircleOutline
        }
    }
})
