
import { defineComponent, PropType, ref } from 'vue'
import { flag as flagIcon } from 'ionicons/icons'
import { format } from 'date-fns'
import { gsap, Power2 } from 'gsap'
import { DeviationVm } from '@dview/shared/models'
import DeviationHocRatingIcon from '../DeviationHocRatingIcon.vue'
import { useDeviationOverlay } from './use-deviation-overlay'
import DeviationListClassificationIcon from './DeviationListClassificationIcon.vue'
import DeviationItemDetails from './DeviationItemDetails.vue'
import { getDeviationDate } from './util'

export default defineComponent({
    components: {
        DeviationListClassificationIcon,
        DeviationHocRatingIcon,
        DeviationItemDetails,
    },
    props: {
        deviation: {
            type: Object as PropType<DeviationVm>,
            required: true,
        },
    },
    setup(props) {
        /* eslint-disable vue/no-setup-props-destructure */
        const deviation = props.deviation
        const deviationType = deviation.type

        // deviation item "header" info, always visible
        const headerDateText = formatItemHeaderDate()
        const headerNewIndicator = deviation.metadata.newlyAdded
        const headerHocRating = deviation.ratingInfo.hocRating // mark as non null for template to not complain
        const headerFlags = generateHeaderFlags()

        // animation helper refs to raw DOM elements
        const deviationItemElement = ref()
        const deviationHeaderElement = ref()
        const deviationDetailsContainerElement = ref()
        const deviationDetailsElement = ref()
        const spacePlaceholderElement = ref()

        const { show: showOverlay, hide: hideOverlay } = useDeviationOverlay()

        const detailsShowing = ref(false)

        /**
         * Display deviations header date as "New" for deviations within the last 24 hours,
         * otherwise display "d MMM" format, eg. 25 May.
         */
        function formatItemHeaderDate() {
            if (deviation.metadata.newlyAdded) {
                return 'new'
            } else {
                return format(getDeviationDate(deviation), 'd MMM')
            }
        }

        function generateHeaderFlags(): HeaderFlags {
            const leadTimeFlag = shouldShowLeadTimeFlag()
            const hasFlags = leadTimeFlag === true
            return {
                hasFlags,
                leadTimeFlag,
            }
        }

        function shouldShowLeadTimeFlag(): boolean {
            if (deviationType === 'open') {
                return deviation.flags.leadtime
            } else {
                return false
            }
        }

        /**
         * Animating the opening of deviation details is a bit peculiar in this situation, since we:
         *  - want to animate an existing inline element into absolute position
         *  - keep the inline occupied space during the animation
         *  - trigger a backdrop overlay animation on a parent component
         *  - listen for outside backdrop click
         * 
         * So it's therefore a mixture of Vue <transition> and manual GSAP animation.
         */
        async function toggleDetails() {
            if (!detailsShowing.value) {
                detailsShowing.value = true
                animateIn()
                await showOverlay()
                await animateOut()
                detailsShowing.value = false
            } else {
                hideOverlay() // will cause the await showOverlay() above to return
            }
        }

        function animateIn() {
            const { item, header, headerTitle, details, detailsContainer, placeholder } = getAnimationElements()

            // activate the placeholder element, which will take up space behind the scenes
            // when we momentarily pull out the actual deviation element to absolute position
            gsap.set(placeholder, {
                display: 'block',
                height: getComputedStyle(item).height
            })

            gsap.set(item, {
                position: 'absolute',
                top: 0,
                zIndex: 2,
                width: '100%'
            })

            header.origBackground = gsap.getProperty(header, 'backgroundColor')
            header.origColor = gsap.getProperty(header, 'color')
            headerTitle.origHeight = getComputedStyle(headerTitle).height

            // animate the deviation header
            gsap.to(header, {
                backgroundColor: '#D9E6F8', // $color-nn-sea-blue-t90;
                color: '#001965', // $color-nn-true-blue
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                ease: Power2.easeOut,
                duration: 0.25
            })

            // animate the deviation header title, incase text overflows and needs to be expanded
            gsap.set(headerTitle, { webkitLineClamp: 'unset', height: headerTitle.origHeight })
            gsap.to(headerTitle, {
                height: 'auto',
                ease: Power2.easeOut,
                duration: 0.25
            })

            // animate the deviation details
            gsap.set(detailsContainer, { display: 'block' })
            gsap.fromTo(details, {
                yPercent: -100,
                opacity: 0,
            }, {
                yPercent: 0,
                opacity: 1,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                ease: Power2.easeOut,
                duration: 0.25
            })
        }

        function animateOut() {
            const { header, headerTitle, details } = getAnimationElements()

            return new Promise<void>(resolve => {
                 gsap.to(details, {
                    yPercent: -100,
                    duration: 0.15,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 0px',
                    ease: Power2.easeOut,
                })

                gsap.to(headerTitle, {
                    height: headerTitle.origHeight,
                    duration: 0.15,
                    ease: Power2.easeOut,
                })

                gsap.to(header, {
                    backgroundColor: header.origBackground,
                    color: header.origColor,
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 0px',
                    duration: 0.15,
                    ease: Power2.easeIn,
                    onComplete: () => {
                        resetAnimationStyles()
                        resolve()
                    }
                })
            })
        }

        function getAnimationElements() {
            const item = deviationItemElement.value
            const header = deviationHeaderElement.value
            const headerTitle = (header as HTMLElement).querySelector('.deviation-title') as any
            const detailsContainer = deviationDetailsContainerElement.value
            const details = deviationDetailsElement.value
            const placeholder = spacePlaceholderElement.value

            return {
                item, header, headerTitle, details, detailsContainer, placeholder
            }
        }

        function resetAnimationStyles() {
            const { item, header, headerTitle, details, detailsContainer, placeholder } = getAnimationElements()
            gsap.set(item, { clearProps: 'all' })
            gsap.set(header, { clearProps: 'all' })
            gsap.set(headerTitle, { clearProps: 'all' })
            gsap.set(placeholder, { clearProps: 'all' })
            gsap.set(detailsContainer, { clearProps: 'all' })
            gsap.set(details, { clearProps: 'all' })
        }

        return {
            deviationType,

            // animation details related
            detailsShowing,
            deviationItemElement,
            deviationHeaderElement,
            deviationDetailsContainerElement,
            deviationDetailsElement,
            spacePlaceholderElement,

            // deviation item header data
            headerNewIndicator,
            headerHocRating,
            headerDateText,
            headerFlags,

            // icons
            flagIcon,

            // actions
            toggleDetails,
        }
    },
})

interface HeaderFlags {
    hasFlags: boolean
    leadTimeFlag: boolean
}
