
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: 'currentColor'
        }
    },
})
