import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65b2c24e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-descriptor" }
const _hoisted_2 = { class: "group-title" }
const _hoisted_3 = { class: "deviation-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviationItem = _resolveComponent("DeviationItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["deviation-group", _ctx.backgroundColor])
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.iconDescriptor), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.groupTitle), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.totalCount), 1)
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.deviations, (deviation) => {
      return (_openBlock(), _createBlock(_component_DeviationItem, {
        deviation: deviation,
        key: deviation.id,
        class: "deviation-item"
      }, null, 8, ["deviation"]))
    }), 128))
  ], 64))
}