import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-659bb6e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }
const _hoisted_2 = { class: "icon-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_icon, {
      icon: _ctx.icon,
      "data-testid": "ionicon",
      style: _normalizeStyle({ color: _ctx.color })
    }, null, 8, ["icon", "style"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}