import { inject, provide, ref, Ref, watch } from 'vue'

export function useDeviationOverlay() {
    const visible = instance()

    /**
     * Uses provide / inject to tie a particular deviation overlay instance to a
     * given parent component and its hierarchy of child components.
     */
    function instance() {
        return inject<Ref<boolean>>('deviation-overlay', ref(false))!
    }

    function init() {
        provide('deviation-overlay', visible)
    }

    function hide() {
        visible.value = false
    }

    function show() {
        visible.value = true
        return new Promise<void>(resolve => {
            watch(visible, visible => {
                if (!visible) {
                    resolve()
                } 
            })
        })
    }

    return {
        hide,
        init,
        show,
        visible
    } as DeviationOverlay
}

/**
 * Public accessible client methods.
 */
interface DeviationOverlay {
    hide(): void
    show(): Promise<void>
}

/**
 * Special hidden methods used by DeviationOverlay component and the initiating parent component.
 */
export interface DeviationOverlayAdvanced extends DeviationOverlay {
    visible: Ref<boolean>
    init(): void
}