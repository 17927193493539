import { ionicInfiniteScrollRef } from '@dview/core/ionic/component-ref'
import { DeviationVm } from '@dview/shared/models'
import { nextTick, ref, Ref } from 'vue'

export function useInfiniteScroll(deviations: Ref<DeviationVm[]>) {
    const infiniteScrollRef = ionicInfiniteScrollRef()
    const infiniteParentScrollContainerRef = ref<HTMLElement | null>()
    const disabled = ref(true)

    const itemsToRender = 15
    const renderedItems = ref<DeviationVm[]>([])

    const hasMoreItemsToRender = () => deviations.value.length > renderedItems.value.length

    function renderMoreItems() {
        if (hasMoreItemsToRender()) {
            disabled.value = false
            const endIndex = renderedItems.value.length + itemsToRender
            const startIndex = endIndex - itemsToRender
            const newItemsToRender = deviations.value.slice(startIndex, endIndex)
            renderedItems.value.push(...newItemsToRender)
            infiniteScrollRef.complete()
            nextTick(() => {
                if (moreViewportRenderSpaceAvailable() && hasMoreItemsToRender()) {
                    renderMoreItems()
                }
            })
        } else {
            disabled.value = true
        }
    }

    // kind of hacksy way to determine if there is still space to initially render more items..
    // problem is that ion-infinite-scroll does not trigger scroll event more than once per viewport
    // change, so we have to manually check if the scrollable content is larger than the viewport of
    // its scroll container, but ion-content uses an inaccessible shadow dom scrolling element.. :/
    function moreViewportRenderSpaceAvailable() {
        const scrollContainer = infiniteParentScrollContainerRef.value
        const scrollableContent = (infiniteScrollRef.value as any).$el.parentElement as HTMLElement
        if (scrollContainer) {
            const hasVerticalScrollbar = scrollableContent.scrollHeight > scrollContainer.clientHeight
            return !hasVerticalScrollbar
        }
        return false
    }

    function reset() {
        renderedItems.value = []
        disabled.value = true
    }

    return {
        infiniteScrollRef,
        infiniteParentScrollContainerRef,
        reset,
        renderMoreItems,
        renderedItems,
        disabled,
    }
}