import { Ref, computed } from 'vue'
import { DeviationVm, DeviationType, DeviationClassification, DeviationPhase } from '@dview/shared/models'
import { useDeviationsFilterConfig } from '@dview/logic'

export function useDeviationGrouping(allDeviations: Ref<DeviationVm[]>, renderedDeviations: Ref<DeviationVm[]>, deviationType: DeviationType) {
    const { sortDirection, sortKey } = useDeviationsFilterConfig(deviationType)

    function createDeviationGroups() {
        if (sortKey.value === 'date') {
            return createNewOldGroups()
        } else if (sortKey.value === 'classification') {
            return createClassificationGrouping()
        } else if (sortKey.value === 'flagged') {
            return createFlaggedGrouping()
        } else if (sortKey.value === 'phase') {
            return createPhaseGrouping()
        } else if (sortKey.value === 'hoc-rating') {
            return createHocRatingGrouping()
        } else {
            return createSingleSortGroupHeader()
        }
    }

    function createNewOldGroups() {
        const newDeviations = createDeviationGroup('New', DeviationGroupType.New, deviation => deviation.metadata.newlyAdded)
        const oldDeviations = createDeviationGroup('Old', DeviationGroupType.Old, deviation => !deviation.metadata.newlyAdded)

        if (sortDirection.value === 'descending') {
            return [ newDeviations, oldDeviations ]
        } else {
            return [ oldDeviations, newDeviations ]
        }
    }

    function createClassificationGrouping() {
        const major = createDeviationGroup('Major', DeviationGroupType.Standard, deviation => deviation.classification === DeviationClassification.Major)
        const minor = createDeviationGroup('Minor', DeviationGroupType.Standard,deviation => deviation.classification === DeviationClassification.Minor)
        const unclassified = createDeviationGroup('Unclassified', DeviationGroupType.Standard, deviation => deviation.classification === DeviationClassification.Unclassifed)

        if (sortDirection.value === 'descending') {
            return [ major, minor, unclassified ]
        } else {
            return [ unclassified, minor, major ]
        }
    }

    function createFlaggedGrouping() {
        //const critical = createDeviationGroup('Critical', DeviationGroupType.Standard, deviation => deviation.classification === DeviationClassification.Major) // TODO: implement at a later stage
        const warning = createDeviationGroup('Warning', DeviationGroupType.Standard, deviation => deviation.flags.leadtime) // TODO: when critical deviations are introduced, need to have specified in view model if it is critical or warning
        const nonFlagged = createDeviationGroup('Not flagged', DeviationGroupType.Standard, deviation => !deviation.flags.leadtime)

        if (sortDirection.value === 'descending') {
            return [ warning, nonFlagged ]
        } else {
            return [ nonFlagged, warning ]
        }
    }

    function createPhaseGrouping() {
        const newPhase = createDeviationGroup('New phase', DeviationGroupType.Standard, deviation => deviation.phase === DeviationPhase.New)
        const approvalPhase = createDeviationGroup('Approval phase', DeviationGroupType.Standard, deviation => deviation.phase === DeviationPhase.Approval)
        const completedPhase = createDeviationGroup('Completed phase', DeviationGroupType.Standard, deviation => deviation.phase === DeviationPhase.Completed)
        const cancelledPhase = createDeviationGroup('Cancelled phase', DeviationGroupType.Standard, deviation => deviation.phase === DeviationPhase.Cancelled)

        if (sortDirection.value === 'descending') {
            return [ newPhase, approvalPhase, completedPhase, cancelledPhase ]
        } else {
            return [ cancelledPhase, completedPhase, approvalPhase, newPhase ]
        }
    }

    function createHocRatingGrouping() {
        const rating0 = createDeviationGroup('No Control', DeviationGroupType.Standard, deviation => deviation.ratingInfo.hocRated && deviation.ratingInfo.hocRating === 0)
        const rating1 = createDeviationGroup('Lagging Control', DeviationGroupType.Standard, deviation => deviation.ratingInfo.hocRated && deviation.ratingInfo.hocRating === 1)
        const rating2 = createDeviationGroup('Sign / Information / Training', DeviationGroupType.Standard, deviation => deviation.ratingInfo.hocRated && deviation.ratingInfo.hocRating === 2)
        const rating3 = createDeviationGroup('Training 4P', DeviationGroupType.Standard, deviation => deviation.ratingInfo.hocRated && deviation.ratingInfo.hocRating === 3)
        const rating4 = createDeviationGroup('Procedure / Standard', DeviationGroupType.Standard, deviation => deviation.ratingInfo.hocRated && deviation.ratingInfo.hocRating === 4)
        const rating5 = createDeviationGroup('Leading Control', DeviationGroupType.Standard, deviation => deviation.ratingInfo.hocRated && deviation.ratingInfo.hocRating === 5)
        const rating6 = createDeviationGroup('Poka Yoke', DeviationGroupType.Standard, deviation => deviation.ratingInfo.hocRated && deviation.ratingInfo.hocRating === 6)

        if (sortDirection.value === 'descending') {
            return [ rating6, rating5, rating4, rating3, rating2, rating1, rating0 ]
        } else {
            return [ rating0, rating1, rating2, rating3, rating4, rating5, rating6 ]
        }
    }

    function createSingleSortGroupHeader(title = ''): DeviationGroup[] {
        return [{
            deviationType,
            groupType: DeviationGroupType.Standard,
            title,
            deviations: renderedDeviations.value,
            totalCount: allDeviations.value.length
        }]
    }

    function createDeviationGroup(title: string, groupType: DeviationGroupType, filterFunction: (deviation: DeviationVm) => boolean): DeviationGroup {
        return {
            deviationType,
            groupType,
            title,
            deviations: renderedDeviations.value.filter(filterFunction),
            totalCount: allDeviations.value.filter(filterFunction).length,
        }
    }

    return {
        groupedDeviations: computed(() => createDeviationGroups())
    }
}

export interface DeviationGroup {
    groupType: DeviationGroupType
    deviationType: DeviationType
    title: string
    deviations: DeviationVm[]
    totalCount: number
}

// special handling for "new" and "older" sorting headers for now
export enum DeviationGroupType {
    New,
    Old,
    Standard
}