
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        header: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        valueMarkingColor: {
            type: String,
            required: false
        }
    }
})
