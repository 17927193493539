import { DeviationType, DeviationVm } from '@dview/shared/models'

export function getDeviationDate(deviation: DeviationVm) {
    const { date, type } = deviation

    if (type === DeviationType.Open) {
        return date.created
    } else if (type === DeviationType.Closed) {
        return date.approved
    } else if (type === DeviationType.HoC) {
        return date.ratingDate
    } else {
        return null!
    }
}