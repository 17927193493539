
import { defineComponent } from 'vue'
import { useDeviationOverlay, DeviationOverlayAdvanced } from './use-deviation-overlay'

export default defineComponent({
    setup() {
        const { visible, hide } = useDeviationOverlay() as DeviationOverlayAdvanced

        return {
            visible,
            hide
        }
    },
})
