import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-054180f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "filter-banner-container"
}
const _hoisted_2 = {
  key: 4,
  class: "deviations-overview-list"
}
const _hoisted_3 = {
  class: "component-root-element",
  ref: "componentRootElement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DViewMessageBanner = _resolveComponent("DViewMessageBanner")!
  const _component_DeviationsNoFilterSelected = _resolveComponent("DeviationsNoFilterSelected")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_DeviationsErrorMessage = _resolveComponent("DeviationsErrorMessage")!
  const _component_DeviationsEmptyMessage = _resolveComponent("DeviationsEmptyMessage")!
  const _component_DeviationOverlay = _resolveComponent("DeviationOverlay")!
  const _component_DeviationGroupComponent = _resolveComponent("DeviationGroupComponent")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "content-slide-up" }, {
      default: _withCtx(() => [
        (_ctx.showWidgetShortcutFilter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_Transition, { name: "filter-banner-slide-out" }, {
                default: _withCtx(() => [
                  (_ctx.widgetShortcutFilterApplied)
                    ? (_openBlock(), _createBlock(_component_DViewMessageBanner, {
                        key: 0,
                        type: "info",
                        message: _ctx.widgetShortcutFilterText,
                        "banner-action-type": "text",
                        "banner-action-text": "Clear filter",
                        onBannerAction: _ctx.clearFilter
                      }, null, 8, ["message", "onBannerAction"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.noAreaSelected)
      ? (_openBlock(), _createBlock(_component_DeviationsNoFilterSelected, { key: 0 }))
      : (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ion_spinner, {
            key: 1,
            name: "crescent",
            slot: "fixed"
          }))
        : (_ctx.error)
          ? (_openBlock(), _createBlock(_component_DeviationsErrorMessage, { key: 2 }))
          : (_ctx.empty)
            ? (_openBlock(), _createBlock(_component_DeviationsEmptyMessage, {
                key: 3,
                "deviation-type": _ctx.deviationType
              }, null, 8, ["deviation-type"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_DeviationOverlay),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.groupedDeviations, (group) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    (group.deviations.length)
                      ? (_openBlock(), _createBlock(_component_DeviationGroupComponent, {
                          group: group,
                          key: group.groupType
                        }, null, 8, ["group"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 256)),
                _createVNode(_component_ion_infinite_scroll, {
                  ref: "infiniteScrollRef",
                  threshold: "100px",
                  disabled: _ctx.ionScrollDisabled,
                  onIonInfinite: _ctx.renderMoreItems
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_infinite_scroll_content, {
                      "loading-spinner": "null",
                      "loading-text": "Loading deviations..."
                    })
                  ]),
                  _: 1
                }, 8, ["disabled", "onIonInfinite"])
              ])),
    _createElementVNode("div", _hoisted_3, null, 512)
  ], 64))
}