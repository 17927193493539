
import { PropType, defineComponent } from 'vue'
import { format } from 'date-fns'
import { openOutline } from 'ionicons/icons'
import { DeviationPhase, DeviationVm, DeviationType } from '@dview/shared/models'
import { getDeviationDate } from './util'
import DeviationProperty from './DeviationProperty.vue'
import { isPlatform } from '@ionic/vue'

export default defineComponent({
    components: {
        DeviationProperty,
    },
    props: {
        deviation: {
            type: Object as PropType<DeviationVm>,
            required: true,
        },
    },
    setup(props) {
        /* eslint-disable vue/no-setup-props-destructure */
        const deviation = props.deviation
        const deviationType = deviation.type

        // For open deviations we want to show the Discovery Date instead of the Creation date in this panel,
        // but Creation date is still the one to use for grouping
        const deviationDate = deviationType === 'open' ? deviation.date.discovery : getDeviationDate(deviation)
        const dateFormatted = format(deviationDate, 'yyyy-MM-dd')
        const dateHeader = getDateName()
        const batchRelatedText = deviation.metadata.batchRelated ? 'Yes' : 'No'
        const hocRatingText = getHocRatingText()
        const phaseText = getPhaseText()
        const leadTime = formatLeadTimeDisplay()

        const novoglowLinkVisible = isPlatform('desktop')
        const novoglowLink = `https://novoglow.novonordisk.com/prod/reliance?ETQ$CMD=CMD_OPEN_DOC&ETQ$APPLICATION_NAME=NN_DEVIATION&ETQ$FORM_NAME=DE_DEVIATION&ETQ$KEY_NAME=DEVIATION_ID&ETQ$KEY_VALUE=${deviation.novoglowAppId}`

        function getDateName() {
            if (deviationType === 'open') {
                return 'Discovery Date'
            } else if (deviationType === 'closed') {
                return 'Approval Date'
            } else if (deviationType === 'hoc-rated') {
                return 'Rating Date'
            } else {
                return 'Date'
            }
        }

        function getHocRatingText() {
            const { hocRated, hocRating } = deviation.ratingInfo

            if (!hocRated) {
                return ''
            }

            switch (hocRating) {
                case 0:
                    return 'No Control'
                case 1:
                    return 'Lagging Control'
                case 2:
                    return 'Sign / Information / Training'
                case 3:
                    return 'Training 4P'
                case 4:
                    return 'Procedure / Standard'
                case 5:
                    return 'Leading Control'
                case 6:
                    return 'Poka Yoke'
                default:
                    return 'Unknown'
            }
        }

        function getPhaseText() {
            if (deviation.phase === DeviationPhase.New) {
                return 'New'
            } else if (deviation.phase === DeviationPhase.Approval) {
                return 'Approval'
            } else if (deviation.phase === DeviationPhase.Completed) {
                return 'Completed'
            } else if (deviation.phase === DeviationPhase.Cancelled) {
                return 'Cancelled'
            } else {
                return 'N/A'
            }
        }

        function formatLeadTimeDisplay(): LeadTime {
            if (deviationType === DeviationType.Open) {
                const leadtimeDays = deviation.metadata.leadtimeDays
                const pluralWording = leadtimeDays === 1 ? 'day' : 'days'
                return {
                    daysSinceCreatedText: `${leadtimeDays} ${pluralWording}`,
                    showWarningMarking: deviation.flags.leadtime,
                }
            } else {
                return {
                    daysSinceCreatedText: '',
                    showWarningMarking: false,
                }
            }
        }

        return {
            deviationType,
            dateFormatted,
            dateHeader,
            batchRelatedText,
            hocRatingText,
            phaseText,
            leadTime,
            novoglowLinkVisible,
            novoglowLink,
            openOutline,
        }
    },
})

interface LeadTime {
    daysSinceCreatedText: string
    showWarningMarking: boolean
}
