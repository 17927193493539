
import { defineComponent } from 'vue'
import { errorLinkBroken } from '@dview/shared/icons'

export default defineComponent({
    setup() {
        return {
            errorLinkBroken
        }
    }
})
