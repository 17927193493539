
import { defineComponent } from 'vue'
import { businessOutline } from 'ionicons/icons'

export default defineComponent({
    setup() {
        return {
            businessOutline
        }
    }
})
