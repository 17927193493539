import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02341a24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "property-container" }
const _hoisted_2 = { class: "header text-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.header), 1),
    _createElementVNode("div", {
      class: "value text-body-active",
      style: _normalizeStyle({ backgroundColor: _ctx.valueMarkingColor ?? 'initial' })
    }, _toDisplayString(_ctx.value), 5)
  ]))
}