import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e00ed74a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "deviation-item-container" }
const _hoisted_2 = {
  class: "space-placeholder",
  ref: "spacePlaceholderElement"
}
const _hoisted_3 = {
  class: "deviation-item",
  ref: "deviationItemElement"
}
const _hoisted_4 = { class: "deviation-status-icon" }
const _hoisted_5 = { class: "deviation-title" }
const _hoisted_6 = { class: "deviation-info" }
const _hoisted_7 = {
  key: 0,
  class: "deviation-flags"
}
const _hoisted_8 = { class: "deviation-date" }
const _hoisted_9 = {
  class: "deviation-details-container",
  ref: "deviationDetailsContainerElement"
}
const _hoisted_10 = {
  class: "deviation-details",
  ref: "deviationDetailsElement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeviationListClassificationIcon = _resolveComponent("DeviationListClassificationIcon")!
  const _component_DeviationHocRatingIcon = _resolveComponent("DeviationHocRatingIcon")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_DeviationItemDetails = _resolveComponent("DeviationItemDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["deviation-item-header", { 'highlighted-new': _ctx.headerNewIndicator }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDetails && _ctx.toggleDetails(...args))),
        ref: "deviationHeaderElement"
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.deviationType === 'open' || _ctx.deviationType === 'closed')
            ? (_openBlock(), _createBlock(_component_DeviationListClassificationIcon, {
                key: 0,
                ref: "iconRef",
                classificationId: _ctx.deviation.classification
              }, null, 8, ["classificationId"]))
            : (_openBlock(), _createBlock(_component_DeviationHocRatingIcon, {
                key: 1,
                class: "hoc-rating-icon",
                "hoc-rating": _ctx.headerHocRating
              }, null, 8, ["hoc-rating"]))
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.deviation.title), 1),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.headerFlags.hasFlags)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.headerFlags.leadTimeFlag)
                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                      key: 0,
                      icon: _ctx.flagIcon,
                      class: "leadtime"
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.headerDateText), 1)
        ])
      ], 2),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_DeviationItemDetails, { deviation: _ctx.deviation }, null, 8, ["deviation"])
        ], 512)
      ], 512)
    ], 512)
  ]))
}