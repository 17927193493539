
import { defineComponent, PropType, computed } from 'vue'
import DeviationItem from './DeviationItem.vue'
import { DeviationGroup, DeviationGroupType } from './use-deviations-grouping'

export default defineComponent({
    components: {
        DeviationItem
    },
    props: {
        group: {
            type: Object as PropType<DeviationGroup>,
            required: true,
        },
    },
    setup(props) {
        const group = computed(() => props.group)
        const groupTitle = computed(() => group.value.title)
        const iconDescriptor = computed(() => getIconDescriptorText())
        const totalCount = computed(() => getTotalCountText())
        const deviations = computed(() => group.value.deviations)
        const backgroundColor = computed(() => getBackgroundColor())

        function getIconDescriptorText() {
            if (isHocRating()) {
                return 'HoC'
            } else {
                return 'Classification'
            }
        }

        function getTotalCountText() {
            if (group.value.groupType === DeviationGroupType.New) {
                return `${group.value.totalCount} new`
            } else {
                return `${group.value.totalCount} in total`
            }
        }

        function isHocRating() {
            return group.value.deviationType === 'hoc-rated'
        }

        function getBackgroundColor() {
            if (group.value.groupType === DeviationGroupType.New || group.value.groupType === DeviationGroupType.Standard) {
                return `background-blue`
            } else {
                return `background-grey` // "Old" sort group header get special visual treatment for now
            }
        }

        return {
            groupTitle, 
            iconDescriptor,
            totalCount,
            backgroundColor,
            deviations
        }
    },
})
